import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { ReactNode, useEffect, useState } from 'react'
import { AirdropErrors, prepareSwellDaoClaimAirdrop } from './airdropCalls'
import { AirdropOffchainState, AirdropResult } from '@/state/dao/types'
import { Loading } from './views/Loading'
import {
  useAirdropLoading,
  useCachedClaimAirdropArguments,
} from './airdropHooks'
import { AirdropStep } from './types'
import { NotEligible } from './views/NotEligible'
import { Intro } from './views/Intro'
import { Token } from '@/types/tokens'
import { Completion } from './views/Completion'
import styled from 'styled-components'
import { StepperControls } from '@/components/AirdropCarousel/AirdropStepper'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Unconnected } from './views/Unconnected'
import { ClaimAirdropToast } from './AirdropToasts'
import { keyframes } from 'styled-components'
import { usePageDimensions } from '@/hooks/usePageDimensions'
import { FlexRow } from '@/swell-ui/FlexRow'
import { AirdropToSModal } from './AirdropToSModal'
import { formatWithConfig } from '@/util/number'
import { SybilDetected } from './views/SybilDetected'
import { ClaimAirdrop } from './views/ClaimAirdrop'
import { AirdropTheJourney } from './views/AirdropTheJourney'
import { AirdropTheFuture } from './views/AirdropTheFuture'
import { AirdropNewSwellEra } from './views/AirdropNewSwellEra'
import { useMediaQuery } from '@mui/material'
import { MerkleContractsState } from '@/types/merkle'
import { BelowThreshold } from './views/BelowThreshold'

const NAV_HEIGHT = 120
const NAV_HEIGHT_MOBILE = 120 + 54 + 24

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export function initialStepAirdropCarousel({
  airdrop,
}: {
  airdrop: AirdropResult
}): AirdropStep {
  if (!airdrop.exists) {
    // invalid state
    return AirdropStep.Intro
  }

  const {
    data: { totalAmount },
    cumulativeClaimed,
  } = airdrop

  const alreadyClaimed = airdrop.claimedEvents.length > 0
  const moreToClaimAtSomePoint = cumulativeClaimed.lt(totalAmount)

  if (alreadyClaimed) {
    if (moreToClaimAtSomePoint) {
      return AirdropStep.Claim
    }

    return AirdropStep.Complete
  }

  return AirdropStep.Intro
}

// @dev requires desktop or above
export function AirdropCarousel({
  claimAirdrop,
  airdropResult,
  minimumPearls,
  daoToken,
  airdropContractsState,
  airdropOffchainState,
  restakedDaoToken,
  planktonPearlsThreshold,
  checkerMode,
}: {
  airdropResult: AirdropResult | undefined
  minimumPearls: number
  claimAirdrop: SwellDaoClaimAirdrop
  daoToken: Token
  airdropContractsState: MerkleContractsState | undefined
  airdropOffchainState: AirdropOffchainState | undefined
  restakedDaoToken: Token
  planktonPearlsThreshold: number
  checkerMode: boolean
}) {
  const is740Up = useMediaQuery('(min-width:740px)')
  const pageDimensions = usePageDimensions()
  const { account } = useSwellWeb3()
  const displayLoading = useAirdropLoading({
    airdropResult,
    airdropContractsState,
    airdropOffchainState,
  })

  let content: ReactNode = null

  if (!account) {
    content = <Unconnected />
  } else if (
    displayLoading ||
    !airdropResult ||
    !airdropContractsState ||
    !airdropOffchainState
  ) {
    content = <Loading />
  } else {
    content = (
      <AirdropCarouselLoaded
        airdropResult={airdropResult}
        claimAirdrop={claimAirdrop}
        daoToken={daoToken}
        minimumPearls={minimumPearls}
        airdropOffchainState={airdropOffchainState}
        airdropContractsState={airdropContractsState}
        restakedDaoToken={restakedDaoToken}
        checkerMode={checkerMode}
        planktonPearlsThreshold={planktonPearlsThreshold}
      />
    )
  }

  let heightPx = 0
  if (pageDimensions?.pageHeight) {
    if (is740Up) {
      heightPx = pageDimensions.pageHeight - NAV_HEIGHT
    } else {
      heightPx = pageDimensions.pageHeight - NAV_HEIGHT_MOBILE
    }
  }

  return (
    <Layout align="center" heightPx={heightPx} direction="column">
      {content}
    </Layout>
  )
}
const Layout = styled(FlexRow)<{ heightPx: number }>`
  min-height: ${(props) => props.heightPx}px;
  visibility: ${(props) => (props.heightPx ? 'visible' : 'hidden')};
  > div {
    opacity: 0;
    animation: ${fadeIn} 0.65s ease-out forwards;
    animation-delay: 0.35s;
  }
`

function AirdropCarouselLoaded({
  airdropResult,
  claimAirdrop,
  minimumPearls,
  daoToken,
  restakedDaoToken,
  airdropOffchainState,
  airdropContractsState,
  checkerMode,
  planktonPearlsThreshold,
}: {
  airdropResult: AirdropResult
  minimumPearls: number
  airdropContractsState: MerkleContractsState
  airdropOffchainState: AirdropOffchainState
  claimAirdrop: SwellDaoClaimAirdrop
  daoToken: Token
  restakedDaoToken: Token
  checkerMode: boolean
  planktonPearlsThreshold: number
}) {
  const { merkleDrop: merkleStatus, staking: stakingStatus } =
    airdropContractsState

  const [lockRatio, setLockRatio] = useState(0)
  const preparedClaimAirdrop = prepareSwellDaoClaimAirdrop({
    airdropResult,
    lockRatio,
    minimumPearls,
    merkleStatus,
    stakingStatus,
  })
  const cachedClaimAirdropArguments = useCachedClaimAirdropArguments({
    claimAirdrop,
  })
  const claimFlowCompleted = !!cachedClaimAirdropArguments

  const [step, setStep] = useState<AirdropStep>(() => {
    return initialStepAirdropCarousel({ airdrop: airdropResult })
  })
  const nextStep = () => {
    if (claimFlowCompleted) return
    if (step === AirdropStep.Complete) return
    setStep((step + 1) as AirdropStep)
  }
  const prevStep = () => {
    if (claimFlowCompleted) return
    if (step === AirdropStep.Intro) return
    setStep((step - 1) as AirdropStep)
  }
  // logic to set the stepper to the Complete view when the claim flow is done
  useEffect(() => {
    if (claimFlowCompleted) {
      setStep(AirdropStep.Complete)
    }
  }, [claimFlowCompleted])

  // animation: smooth step transitions, makes the step # continuous and controls fading in/out with a sine wave
  const [stepContinuous, setStepContinuous] = useState(step as number)
  useEffect(() => {
    let done = false
    const animate = () => {
      if (done) return
      setStepContinuous((prev) => {
        if (prev === step) return prev
        const diff = step - prev
        const next = prev + diff * 0.2
        return next
      })
      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [step])
  const contentOpacity = Math.abs(Math.cos((stepContinuous % 1) * Math.PI))

  const pearlsStr = formatWithConfig(airdropResult.pearls, {
    localize: true,
    precision: 0,
  })

  const roundedStep = Math.round(stepContinuous)

  let showStepper = true
  let showToS = true
  let content: ReactNode = null
  if (preparedClaimAirdrop.error === AirdropErrors.SybilDetected) {
    content = <SybilDetected pearlsStr={pearlsStr} />
    showStepper = false
    showToS = false
  } else if (preparedClaimAirdrop.error === AirdropErrors.IneligiblePearls) {
    content = <BelowThreshold pearlsStr={pearlsStr} />
    showStepper = false
    showToS = false
  } else if (preparedClaimAirdrop.error === AirdropErrors.NoAirdropData) {
    content = <NotEligible />
    showStepper = false
    showToS = false
  } else if (roundedStep === AirdropStep.Intro) {
    content = (
      <Intro
        airdropResult={airdropResult}
        daoToken={daoToken}
        pearlsStr={pearlsStr}
        isPlankton={airdropResult.pearls <= planktonPearlsThreshold}
        start={nextStep}
        checkerMode={checkerMode}
        airdropContractsState={airdropContractsState}
      />
    )
    showStepper = false
    showToS = false
  } else if (roundedStep === AirdropStep.TheJourney) {
    content = <AirdropTheJourney />
  } else if (roundedStep === AirdropStep.TheFuture) {
    content = <AirdropTheFuture />
  } else if (roundedStep === AirdropStep.NewSwellEra) {
    content = <AirdropNewSwellEra />
  } else if (roundedStep === AirdropStep.Claim) {
    content = (
      <ClaimAirdrop
        claimAirdrop={claimAirdrop}
        airdropResult={airdropResult}
        daoToken={daoToken}
        lockRatio={lockRatio}
        setLockRatio={setLockRatio}
        merkleStatus={merkleStatus}
        preparedClaimAirdrop={preparedClaimAirdrop}
        nextAirdropVestingUnlockUnix={0}
      />
    )

    const isRevisitingWhale =
      airdropResult.claimedEvents?.length &&
      airdropResult.claimedEvents.length > 0

    if (isRevisitingWhale) {
      showStepper = false
    }
  } else if (roundedStep === AirdropStep.Complete) {
    content = (
      <Completion
        cachedClaimAirdropArguments={cachedClaimAirdropArguments}
        daoToken={daoToken}
        airdropResult={airdropResult}
        restakedDaoToken={restakedDaoToken}
      />
    )
    showToS = false
    showStepper = false
  }

  if (!airdropContractsState.merkleDrop.claimIsOpen) {
    showStepper = false
    showToS = false
  }

  if (checkerMode) {
    showStepper = false
    showToS = false
  }

  return (
    <>
      <StepperControls
        disabled={!airdropContractsState.merkleDrop.claimIsOpen}
        currentStep={step}
        onClickNext={nextStep}
        onClickBack={prevStep}
        showStepper={showStepper}
        firstVisibleStep={AirdropStep.TheJourney}
        lastVisibleStep={AirdropStep.Claim}
      >
        <ContentContainer direction="column" opacity={contentOpacity}>
          {content}
        </ContentContainer>
      </StepperControls>

      <AirdropToSModal open={showToS} />

      <ClaimAirdropToast
        claimAirdrop={claimAirdrop}
        daoToken={daoToken}
        airdropResult={airdropResult}
      />
    </>
  )
}

const ContentContainer = styled(FlexRow)<{ opacity: number }>`
  flex: 1 0 auto;
  align-self: stretch;
  opacity: ${({ opacity }) => opacity};
`
