// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/wallet.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.TokenBalancesRequest
 */
export class TokenBalancesRequest extends Message<TokenBalancesRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<TokenBalancesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalancesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalancesRequest | PlainMessage<TokenBalancesRequest> | undefined, b: TokenBalancesRequest | PlainMessage<TokenBalancesRequest> | undefined): boolean {
    return proto3.util.equals(TokenBalancesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenBalance
 */
export class TokenBalance extends Message<TokenBalance> {
  /**
   * @generated from field: string contract_address = 1;
   */
  contractAddress = "";

  /**
   * @generated from field: string token_balance = 2;
   */
  tokenBalance = "";

  constructor(data?: PartialMessage<TokenBalance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contract_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token_balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalance {
    return new TokenBalance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalance {
    return new TokenBalance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalance {
    return new TokenBalance().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalance | PlainMessage<TokenBalance> | undefined, b: TokenBalance | PlainMessage<TokenBalance> | undefined): boolean {
    return proto3.util.equals(TokenBalance, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenBalancesResponse
 */
export class TokenBalancesResponse extends Message<TokenBalancesResponse> {
  /**
   * @generated from field: repeated swell.v3.TokenBalance token_balances = 1;
   */
  tokenBalances: TokenBalance[] = [];

  constructor(data?: PartialMessage<TokenBalancesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalancesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_balances", kind: "message", T: TokenBalance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalancesResponse | PlainMessage<TokenBalancesResponse> | undefined, b: TokenBalancesResponse | PlainMessage<TokenBalancesResponse> | undefined): boolean {
    return proto3.util.equals(TokenBalancesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenMetadataRequest
 */
export class TokenMetadataRequest extends Message<TokenMetadataRequest> {
  /**
   * @generated from field: string token_address = 1;
   */
  tokenAddress = "";

  constructor(data?: PartialMessage<TokenMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenMetadataRequest | PlainMessage<TokenMetadataRequest> | undefined, b: TokenMetadataRequest | PlainMessage<TokenMetadataRequest> | undefined): boolean {
    return proto3.util.equals(TokenMetadataRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenMetadataResponse
 */
export class TokenMetadataResponse extends Message<TokenMetadataResponse> {
  /**
   * @generated from field: int32 chain_id = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string address = 2;
   */
  address = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string symbol = 4;
   */
  symbol = "";

  /**
   * @generated from field: int32 decimals = 5;
   */
  decimals = 0;

  /**
   * @generated from field: string logo_uri = 6;
   */
  logoUri = "";

  constructor(data?: PartialMessage<TokenMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "logo_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenMetadataResponse | PlainMessage<TokenMetadataResponse> | undefined, b: TokenMetadataResponse | PlainMessage<TokenMetadataResponse> | undefined): boolean {
    return proto3.util.equals(TokenMetadataResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.IsGeoRestrictedRequest
 */
export class IsGeoRestrictedRequest extends Message<IsGeoRestrictedRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  constructor(data?: PartialMessage<IsGeoRestrictedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.IsGeoRestrictedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: IsGeoRestrictedRequest | PlainMessage<IsGeoRestrictedRequest> | undefined, b: IsGeoRestrictedRequest | PlainMessage<IsGeoRestrictedRequest> | undefined): boolean {
    return proto3.util.equals(IsGeoRestrictedRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.IsGeoRestrictedResponse
 */
export class IsGeoRestrictedResponse extends Message<IsGeoRestrictedResponse> {
  /**
   * @generated from field: bool is_restricted = 1;
   */
  isRestricted = false;

  constructor(data?: PartialMessage<IsGeoRestrictedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.IsGeoRestrictedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: IsGeoRestrictedResponse | PlainMessage<IsGeoRestrictedResponse> | undefined, b: IsGeoRestrictedResponse | PlainMessage<IsGeoRestrictedResponse> | undefined): boolean {
    return proto3.util.equals(IsGeoRestrictedResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.CheckIPRequest
 */
export class CheckIPRequest extends Message<CheckIPRequest> {
  /**
   * @generated from field: string ip = 1;
   */
  ip = "";

  constructor(data?: PartialMessage<CheckIPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CheckIPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckIPRequest | PlainMessage<CheckIPRequest> | undefined, b: CheckIPRequest | PlainMessage<CheckIPRequest> | undefined): boolean {
    return proto3.util.equals(CheckIPRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.CheckIPResponse
 */
export class CheckIPResponse extends Message<CheckIPResponse> {
  /**
   * @generated from field: bool is_restricted = 1;
   */
  isRestricted = false;

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * @generated from field: string region = 3;
   */
  region = "";

  /**
   * @generated from field: string ip = 4;
   */
  ip = "";

  /**
   * @generated from field: string timestamp = 5;
   */
  timestamp = "";

  constructor(data?: PartialMessage<CheckIPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CheckIPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckIPResponse | PlainMessage<CheckIPResponse> | undefined, b: CheckIPResponse | PlainMessage<CheckIPResponse> | undefined): boolean {
    return proto3.util.equals(CheckIPResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EigenLayerAirdropRequest
 */
export class EigenLayerAirdropRequest extends Message<EigenLayerAirdropRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<EigenLayerAirdropRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EigenLayerAirdropRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EigenLayerAirdropRequest {
    return new EigenLayerAirdropRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EigenLayerAirdropRequest {
    return new EigenLayerAirdropRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EigenLayerAirdropRequest {
    return new EigenLayerAirdropRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EigenLayerAirdropRequest | PlainMessage<EigenLayerAirdropRequest> | undefined, b: EigenLayerAirdropRequest | PlainMessage<EigenLayerAirdropRequest> | undefined): boolean {
    return proto3.util.equals(EigenLayerAirdropRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EigenLayerAirdropResponse
 */
export class EigenLayerAirdropResponse extends Message<EigenLayerAirdropResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string cumulative_amount = 2;
   */
  cumulativeAmount = "";

  /**
   * @generated from field: repeated string proofs_hex = 3;
   */
  proofsHex: string[] = [];

  /**
   * @generated from field: string latest_merkle_root_hex = 4;
   */
  latestMerkleRootHex = "";

  constructor(data?: PartialMessage<EigenLayerAirdropResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EigenLayerAirdropResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cumulative_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "proofs_hex", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "latest_merkle_root_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EigenLayerAirdropResponse {
    return new EigenLayerAirdropResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EigenLayerAirdropResponse {
    return new EigenLayerAirdropResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EigenLayerAirdropResponse {
    return new EigenLayerAirdropResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EigenLayerAirdropResponse | PlainMessage<EigenLayerAirdropResponse> | undefined, b: EigenLayerAirdropResponse | PlainMessage<EigenLayerAirdropResponse> | undefined): boolean {
    return proto3.util.equals(EigenLayerAirdropResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropRequest
 */
export class AirdropRequest extends Message<AirdropRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<AirdropRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropRequest {
    return new AirdropRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropRequest {
    return new AirdropRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropRequest {
    return new AirdropRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropRequest | PlainMessage<AirdropRequest> | undefined, b: AirdropRequest | PlainMessage<AirdropRequest> | undefined): boolean {
    return proto3.util.equals(AirdropRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropResponse
 */
export class AirdropResponse extends Message<AirdropResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string cumulative_amount = 2;
   */
  cumulativeAmount = "";

  /**
   * @generated from field: string total_amount = 3;
   */
  totalAmount = "";

  /**
   * @generated from field: repeated string proofs_hex = 4;
   */
  proofsHex: string[] = [];

  /**
   * @generated from field: string latest_merkle_root_hex = 5;
   */
  latestMerkleRootHex = "";

  /**
   * @generated from field: string loyalty_amount = 6;
   */
  loyaltyAmount = "";

  /**
   * @generated from field: bool is_sybil = 7;
   */
  isSybil = false;

  /**
   * @generated from field: float pearl_amount = 8;
   */
  pearlAmount = 0;

  constructor(data?: PartialMessage<AirdropResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cumulative_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "proofs_hex", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "latest_merkle_root_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "loyalty_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_sybil", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "pearl_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropResponse {
    return new AirdropResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropResponse {
    return new AirdropResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropResponse {
    return new AirdropResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropResponse | PlainMessage<AirdropResponse> | undefined, b: AirdropResponse | PlainMessage<AirdropResponse> | undefined): boolean {
    return proto3.util.equals(AirdropResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropCheckerRequest
 */
export class AirdropCheckerRequest extends Message<AirdropCheckerRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<AirdropCheckerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropCheckerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropCheckerRequest {
    return new AirdropCheckerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropCheckerRequest {
    return new AirdropCheckerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropCheckerRequest {
    return new AirdropCheckerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropCheckerRequest | PlainMessage<AirdropCheckerRequest> | undefined, b: AirdropCheckerRequest | PlainMessage<AirdropCheckerRequest> | undefined): boolean {
    return proto3.util.equals(AirdropCheckerRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropCheckerResponse
 */
export class AirdropCheckerResponse extends Message<AirdropCheckerResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string total_amount = 2;
   */
  totalAmount = "";

  /**
   * @generated from field: string loyalty_amount = 3;
   */
  loyaltyAmount = "";

  /**
   * @generated from field: bool is_sybil = 4;
   */
  isSybil = false;

  /**
   * @generated from field: bool is_whale = 5;
   */
  isWhale = false;

  /**
   * @generated from field: float pearl_amount = 6;
   */
  pearlAmount = 0;

  constructor(data?: PartialMessage<AirdropCheckerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropCheckerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "loyalty_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_sybil", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_whale", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "pearl_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropCheckerResponse {
    return new AirdropCheckerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropCheckerResponse {
    return new AirdropCheckerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropCheckerResponse {
    return new AirdropCheckerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropCheckerResponse | PlainMessage<AirdropCheckerResponse> | undefined, b: AirdropCheckerResponse | PlainMessage<AirdropCheckerResponse> | undefined): boolean {
    return proto3.util.equals(AirdropCheckerResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.SwellL2EcosystemUserRequest
 */
export class SwellL2EcosystemUserRequest extends Message<SwellL2EcosystemUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<SwellL2EcosystemUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SwellL2EcosystemUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwellL2EcosystemUserRequest {
    return new SwellL2EcosystemUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserRequest {
    return new SwellL2EcosystemUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserRequest {
    return new SwellL2EcosystemUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SwellL2EcosystemUserRequest | PlainMessage<SwellL2EcosystemUserRequest> | undefined, b: SwellL2EcosystemUserRequest | PlainMessage<SwellL2EcosystemUserRequest> | undefined): boolean {
    return proto3.util.equals(SwellL2EcosystemUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.SwellL2EcosystemUserResponse
 */
export class SwellL2EcosystemUserResponse extends Message<SwellL2EcosystemUserResponse> {
  /**
   * @generated from field: float points = 1;
   */
  points = 0;

  constructor(data?: PartialMessage<SwellL2EcosystemUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SwellL2EcosystemUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwellL2EcosystemUserResponse {
    return new SwellL2EcosystemUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserResponse {
    return new SwellL2EcosystemUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserResponse {
    return new SwellL2EcosystemUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SwellL2EcosystemUserResponse | PlainMessage<SwellL2EcosystemUserResponse> | undefined, b: SwellL2EcosystemUserResponse | PlainMessage<SwellL2EcosystemUserResponse> | undefined): boolean {
    return proto3.util.equals(SwellL2EcosystemUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropUserRequest
 */
export class WavedropUserRequest extends Message<WavedropUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<WavedropUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropUserRequest {
    return new WavedropUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropUserRequest {
    return new WavedropUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropUserRequest {
    return new WavedropUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropUserRequest | PlainMessage<WavedropUserRequest> | undefined, b: WavedropUserRequest | PlainMessage<WavedropUserRequest> | undefined): boolean {
    return proto3.util.equals(WavedropUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropUserResponse
 */
export class WavedropUserResponse extends Message<WavedropUserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<WavedropUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropUserResponse {
    return new WavedropUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropUserResponse {
    return new WavedropUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropUserResponse {
    return new WavedropUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropUserResponse | PlainMessage<WavedropUserResponse> | undefined, b: WavedropUserResponse | PlainMessage<WavedropUserResponse> | undefined): boolean {
    return proto3.util.equals(WavedropUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.Wavedrop2UserRequest
 */
export class Wavedrop2UserRequest extends Message<Wavedrop2UserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<Wavedrop2UserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Wavedrop2UserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Wavedrop2UserRequest {
    return new Wavedrop2UserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Wavedrop2UserRequest {
    return new Wavedrop2UserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Wavedrop2UserRequest {
    return new Wavedrop2UserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Wavedrop2UserRequest | PlainMessage<Wavedrop2UserRequest> | undefined, b: Wavedrop2UserRequest | PlainMessage<Wavedrop2UserRequest> | undefined): boolean {
    return proto3.util.equals(Wavedrop2UserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.Wavedrop2UserResponse
 */
export class Wavedrop2UserResponse extends Message<Wavedrop2UserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<Wavedrop2UserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Wavedrop2UserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Wavedrop2UserResponse {
    return new Wavedrop2UserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Wavedrop2UserResponse {
    return new Wavedrop2UserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Wavedrop2UserResponse {
    return new Wavedrop2UserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Wavedrop2UserResponse | PlainMessage<Wavedrop2UserResponse> | undefined, b: Wavedrop2UserResponse | PlainMessage<Wavedrop2UserResponse> | undefined): boolean {
    return proto3.util.equals(Wavedrop2UserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.BtcLrtUserRequest
 */
export class BtcLrtUserRequest extends Message<BtcLrtUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<BtcLrtUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BtcLrtUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BtcLrtUserRequest {
    return new BtcLrtUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BtcLrtUserRequest {
    return new BtcLrtUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BtcLrtUserRequest {
    return new BtcLrtUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BtcLrtUserRequest | PlainMessage<BtcLrtUserRequest> | undefined, b: BtcLrtUserRequest | PlainMessage<BtcLrtUserRequest> | undefined): boolean {
    return proto3.util.equals(BtcLrtUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.BtcLrtUserResponse
 */
export class BtcLrtUserResponse extends Message<BtcLrtUserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<BtcLrtUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BtcLrtUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BtcLrtUserResponse {
    return new BtcLrtUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BtcLrtUserResponse {
    return new BtcLrtUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BtcLrtUserResponse {
    return new BtcLrtUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BtcLrtUserResponse | PlainMessage<BtcLrtUserResponse> | undefined, b: BtcLrtUserResponse | PlainMessage<BtcLrtUserResponse> | undefined): boolean {
    return proto3.util.equals(BtcLrtUserResponse, a, b);
  }
}

