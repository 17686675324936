import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import TagManager from 'react-gtm-module'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SwellWeb3Provider } from '@swell-web3/core'
import { SwellUiThemeProvider } from '@swell-ui/theme/SwellUiThemeProvider'
import store from '@/state'
import ApplicationUpdater from '@/state/application/updater'
import FiatUpdater from './state/fiat/updater'
import GasUpdater from '@/state/gas/updater'
import { BlockNumberProvider } from './hooks/useBlockNumber'
import { SWRConfig } from 'swr'
import './i18n'
import '@reach/dialog/styles.css'
import UserUpdater from './state/user/updater'
import { ENABLE_DEV_GUI } from './configuration/featureFlags'
import { HOST_ENV, HostEnv } from './configuration/hostEnv'
import { env } from './env'
import WrongChainDetection from './components/WrongChainDetection'
import { GeoFenceContext } from './state/geofence/context'
import { ZapContext } from './state/zap/context'
import { useZapApiParaswap } from './state/zap/paraswap'
import { useBtcLrtVaultApiImpl } from './state/yearnVault'
import { BtcLrtVaultContext } from './state/yearnVault/context'
import { TOKEN_LIST_EIGEN, TOKEN_LIST_RSWETH } from './constants/tokens'
import { RswETHContext } from './state/rsweth/context'
import { EXIT_ASSET_RSWETH_ETH } from './constants/exits'
import { useRswETHApiImpl } from './state/rsweth/api'
import { usePredepositApiImpl } from './state/predeposit/api'
import { PredepositContext } from './state/predeposit/context'
import {
  MetaCRMAccount,
  MetaCRMTracking,
  MetaCRMTrackingScript,
  MetaCRMWidgetScript,
} from './components/MetaCRM'
import { SwellDaoContext } from './state/dao/context'
import { useDaoApiMockImpl } from './state/dao/mock'
import { useSwellDaoApiImpl } from './state/dao'
import { useSwellDaoTestnet } from './pages/Airdrop/hooks'
import { ROUTES } from './constants/routes'
import { MERKLE_DROP_EIGEN_STAKEDROP_RSWETH_MAINNET } from './constants/merkleDrops'
import { useGeoFenceApiImplV3Backend } from './state/geofence/api'
import { useAutoConnectGnosis } from './hooks/useAutoConnectGnosis'

const EIGEN_CHECKER_MODE = false

const DevGui = lazy(() => import('./components/DevGui'))

const GTM_ID = env.REACT_APP_GTM_ID

if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID })
} else if (HOST_ENV === HostEnv.PRODUCTION) {
  console.warn(
    'No GTM_ID in production. Google Tag Manager was not initialized.'
  )
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <GasUpdater />
      <FiatUpdater />
      <UserUpdater />
    </>
  )
}

// const mockDao = true
function APIProviders({ children }: { children?: any }) {
  const geoFenceAPI = useGeoFenceApiImplV3Backend()
  const zapApi = useZapApiParaswap()

  const rswETHApi = useRswETHApiImpl({
    exitAssets: [EXIT_ASSET_RSWETH_ETH],
    rswETHToken: TOKEN_LIST_RSWETH,
    eigenStakedropMerkleDrop: MERKLE_DROP_EIGEN_STAKEDROP_RSWETH_MAINNET,
    eigenToken: TOKEN_LIST_EIGEN,
    eigenCheckerMode: EIGEN_CHECKER_MODE,
  })
  const daoAPI = useSwellDaoApiImpl()
  const predepositApi = usePredepositApiImpl()
  const btcLrtVaultApiReal = useBtcLrtVaultApiImpl()

  return (
    <GeoFenceContext.Provider value={geoFenceAPI}>
      <ZapContext.Provider value={zapApi}>
        <RswETHContext.Provider value={rswETHApi}>
          <SwellDaoContext.Provider value={daoAPI}>
            <PredepositContext.Provider value={predepositApi}>
              <BtcLrtVaultContext.Provider value={btcLrtVaultApiReal}>
                {children}
              </BtcLrtVaultContext.Provider>
            </PredepositContext.Provider>
          </SwellDaoContext.Provider>
        </RswETHContext.Provider>
      </ZapContext.Provider>
    </GeoFenceContext.Provider>
  )
}

function Scripts() {
  return (
    <>
      <MetaCRMWidgetScript production={HOST_ENV === HostEnv.PRODUCTION} />
      <MetaCRMTrackingScript production={HOST_ENV === HostEnv.PRODUCTION} />
    </>
  )
}

function Effects() {
  useAutoConnectGnosis()
  return null
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Scripts />
    <BrowserRouter>
      <Provider store={store}>
        <SWRConfig>
          <SwellWeb3Provider>
            <MetaCRMTracking />
            <MetaCRMAccount />
            <BlockNumberProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <APIProviders>
                  <Effects />
                  <SwellUiThemeProvider>
                    <WrongChainDetection />
                    <Updaters />
                    <App />
                    {ENABLE_DEV_GUI && (
                      <Suspense fallback={null}>
                        <DevGui />
                      </Suspense>
                    )}
                  </SwellUiThemeProvider>
                </APIProviders>
              </LocalizationProvider>
            </BlockNumberProvider>
          </SwellWeb3Provider>
        </SWRConfig>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
