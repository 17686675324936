import { SupportedChainId } from './chains'
import swbtcLogo from '@/assets/images/swbtc-logo-64x64.png'
import swellLogo from 'assets/images/swell-logo.png'
import { Token } from '@/types/tokens'
import swellEarnBtcLogo from '@/assets/images/swell-earn-btc-120x120.png'
import swellEarnEthLogo from '@/assets/images/swell-earn-eth-120x120.png'
import ethLogo from '@/assets/images/ethereum-logo.png'
import rswellLogo from '@/assets/images/rswell-icon-120x120.png'
import eigenlayerLogo from 'assets/images/predeposit/eigenlayer.png'

export const TOKEN_LIST_ETH = {
  chainId: 1,
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
  logoURI: ethLogo,
}

export const TOKEN_LIST_SWETH = {
  chainId: 1,
  address: '0xf951e335afb289353dc249e82926178eac7ded78',
  name: 'Swell Ethereum',
  symbol: 'swETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/30326/thumb/_lB7zEtS_400x400.jpg?1684128260',
}

export const TOKEN_LIST_RSWETH = {
  chainId: 1,
  address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
  name: 'Restaked Swell Ethereum',
  symbol: 'rswETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/34489/thumb/Restaked_Swell_ETH.png?1705048450',
}

export const TOKEN_LIST_WBTC = {
  chainId: 1,
  address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  name: 'Wrapped Bitcoin',
  symbol: 'WBTC',
  decimals: 8, // 8 decimals for WBTC
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/668f36f525894f0dbb90cdc1_wBTC.svg',
}

export const TOKEN_LIST_WBTC_SEPOLIA = {
  ...TOKEN_LIST_WBTC,
  decimals: 8,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xfBefEB5dAaCD725CD8943c82B13C4B4499Dc87f5',
}

export const TOKEN_LIST_WBTC_HOLESKY = {
  ...TOKEN_LIST_WBTC,
  decimals: 8,
  chainId: SupportedChainId.HOLESKY,
  address: '0x640577074A5d054b011E0f05114078777646ca8e',
}

export const TOKEN_LIST_SWBTC = {
  chainId: 1,
  address: '0x8DB2350D78aBc13f5673A411D4700BCF87864dDE',
  name: 'swBTC',
  symbol: 'swBTC',
  decimals: 8,
  logoURI: swbtcLogo,
}
export const TOKEN_LIST_BTCLRT = TOKEN_LIST_SWBTC

export const TOKEN_LIST_SWBTC_SEPOLIA = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xeFc03C91C5F11E6B86645337D11f76BbbDBA151F',
}
export const TOKEN_LIST_BTCLRT_SEPOLIA = TOKEN_LIST_SWBTC_SEPOLIA

export const TOKEN_LIST_SWBTC_HOLESKY = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.HOLESKY,
  address: '0xC42aEa23C2E77a0831d7eefc07BB4ec9052503FF',
}
export const TOKEN_LIST_BTCLRT_HOLESKY = TOKEN_LIST_SWBTC_HOLESKY

export const TOKEN_LIST_SWELL = {
  chainId: 1,
  address: '0x0a6E7Ba5042B38349e437ec6Db6214AEC7B35676',
  name: 'SWELL',
  symbol: 'SWELL',
  decimals: 18,
  logoURI: swellLogo,
}
export const TOKEN_LIST_SWELL_SEPOLIA: Token = TOKEN_LIST_WBTC_SEPOLIA

export const TOKEN_LIST_RSWELL = {
  chainId: 1,
  address: '0x00',
  name: 'Restaked SWELL',
  symbol: 'rSWELL',
  decimals: 18,
  logoURI: rswellLogo,
}
export const TOKEN_LIST_RSWELL_SEPOLIA: Token = TOKEN_LIST_SWBTC_SEPOLIA

export const TOKEN_LIST_EARN_ETH = {
  chainId: 1,
  address: '0x9Ed15383940CC380fAEF0a75edacE507cC775f22',
  name: 'Swell Earn ETH',
  symbol: 'earnETH',
  decimals: 18,
  logoURI: swellEarnEthLogo,
}

export const TOKEN_LIST_EARN_BTC = {
  chainId: 1,
  address: '0x215dc1cc32d9d08a0081e55e55895c8cf006839a',
  name: 'Swell Earn BTC',
  symbol: 'earnBTC',
  decimals: 8,
  logoURI: swellEarnBtcLogo,
}

export const TOKEN_LIST_STETH = {
  address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
  chainId: 1,
  decimals: 18,
  logoURI: 'https://static.alchemyapi.io/images/assets/8085.png',
  name: 'Lido Staked ETH',
  symbol: 'stETH',
}

export const TOKEN_LIST_WSTETH = {
  address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6535fb70f080adb719c54a38_wstETH.svg',
  name: 'Lido wstETH',
  symbol: 'wstETH',
}

export const TOKEN_LIST_RETH = {
  address: '0xae78736cd615f374d3085123a210448e74fc6393',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6674fdaa81a28487140322c6_rETH.svg',
  name: 'Rocket Pool ETH',
  symbol: 'rETH',
}

export const TOKEN_LIST_WETH = {
  chainId: 1,
  address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  symbol: 'WETH',
  name: 'WETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1696503332',
  tags: ['ETH'],
}

export const TOKEN_LIST_EETH: Token = {
  name: 'ether.fi ETH',
  symbol: 'eETH',
  address: '0x35fa164735182de50811e8e2e824cfb9b6118ac2',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/33049/standard/ether.fi_eETH.png?1700473063',
}
export const TOKEN_LIST_WEETH: Token = {
  symbol: 'weETH',
  address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/33033/standard/weETH.png?1701438396',
  name: 'Wrapped eETH',
}
export const TOKEN_LIST_CBETH: Token = {
  symbol: 'cbETH',
  address: '0xbe9895146f7af43049ca1c1ae358b0541ea49704',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6674fdaa49894df7ff1417db_cbETH.svg',
  name: 'Coinbase Wrapped Staked ETH',
}
export const TOKEN_LIST_WBETH: Token = {
  symbol: 'wbETH',
  address: '0xa2e3356610840701bdf5611a53974510ae27e2e1',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6674fdaa735f8274b778e909_wBETH.svg',
  name: 'Wrapped Binance Beacon ETH',
}
export const TOKEN_LIST_METH: Token = {
  symbol: 'mETH',
  address: '0xd5f7838f5c461feff7fe49ea5ebaf7728bb0adfa',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6674fdaad3fa560dec70d094_mETH.svg',
  name: 'mETH',
}
export const TOKEN_LIST_SFRXETH: Token = {
  symbol: 'sfrxETH',
  address: '0xac3e018457b222d93114458476f3e3416abbe38f',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d21093d79ece8ce9adbe_sfrxETH.svg',
  name: 'FRAX staked ETH',
}
export const TOKEN_LIST_ETHX: Token = {
  symbol: 'ETHx',
  address: '0xa35b1b31ce002fbf2058d22f30f95d405200a15b',
  chainId: 1,
  decimals: 18,
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6674fdabdebd24a47d49ee4f_ETHx.svg',
  name: 'ETHx',
}
export const TOKEN_LIST_USDC: Token = {
  symbol: 'USDC',
  address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  chainId: 1,
  decimals: 6,
  logoURI:
    'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
  name: 'USD Coin',
}

export const TOKEN_LIST_EIGEN = {
  chainId: 1,
  address: '0xec53bF9167f50cDEB3Ae105f56099aaaB9061F83',
  name: 'Eigen',
  symbol: 'EIGEN',
  decimals: 18,
  logoURI: eigenlayerLogo,
}
