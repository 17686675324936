import { useState } from 'react'
import { useProtobufMutation } from '@/hooks/useProtobufMutation'
import {
  useProtobufQuery,
  useProtobufQueryImmutable,
} from '@/hooks/useProtobufQuery'
import {
  depositManagerStatsAdapter,
  eigenPointsUserAdapter,
  earnAPRsAdapter,
  repriceAdapter,
  stakesAdapter,
  tokenRatesAdapter,
  transactionNativeAdapter,
  transactionTokenAdapter,
  twitterUserAdapter,
  voyageLeaderboardRecordAdapter,
  voyageStatusAdapter,
  voyageUserAdapter,
  tokenListProtobufAdapter,
} from './adapters'
import { createPromiseClient } from '@bufbuild/connect'
import { createConnectTransport } from '@bufbuild/connect-web'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { V3_BACKEND_SERVICES } from '.'
import { useMemo } from 'react'
import {
  OrderBy,
  OrderDirection,
  StakeTransactionType,
} from '@v3-backend/staker_pb'
import useSWR from 'swr'
import axios from 'axios'
import { AVERAGE_L1_BLOCK_TIME } from '@/constants/chainInfo'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { tokenListAdapter } from '../TokenList/adapters'
import { PreDepositStatsResponse } from '@/submodules/v3-shared/ts/connect/swell/v3/predeposit_pb'
import { BigNumber } from 'ethers'

export function useV3BackendClient(baseUrl: string) {
  return useMemo(() => {
    const transport = createConnectTransport({
      baseUrl,
      useHttpGet: true,
    })

    const lifi = createPromiseClient(V3_BACKEND_SERVICES.LifiService, transport)

    const rates = createPromiseClient(
      V3_BACKEND_SERVICES.RatesService,
      transport
    )
    const operator = createPromiseClient(
      V3_BACKEND_SERVICES.OperatorService,
      transport
    )
    const preDeposit = createPromiseClient(
      V3_BACKEND_SERVICES.PreDepositService,
      transport
    )
    const staker = createPromiseClient(
      V3_BACKEND_SERVICES.StakerService,
      transport
    )
    const stats = createPromiseClient(
      V3_BACKEND_SERVICES.StatsService,
      transport
    )
    const twitter = createPromiseClient(
      V3_BACKEND_SERVICES.TwitterService,
      transport
    )
    const voyage = createPromiseClient(
      V3_BACKEND_SERVICES.VoyageService,
      transport
    )
    const eigenPoints = createPromiseClient(
      V3_BACKEND_SERVICES.EigenPointsService,
      transport
    )
    const wallet = createPromiseClient(
      V3_BACKEND_SERVICES.WalletService,
      transport
    )
    const paraswap = createPromiseClient(
      V3_BACKEND_SERVICES.ParaswapService,
      transport
    )

    const v3BackendClient = {
      lifi,
      rates,
      operator,
      preDeposit,
      staker,
      stats,
      twitter,
      voyage,
      eigenPoints,
      wallet,
      paraswap,
    }

    return { v3BackendClient }
  }, [baseUrl])
}

export type V3BackendClient = ReturnType<
  typeof useV3BackendClient
>['v3BackendClient']
export type WalletClient = V3BackendClient['wallet']
export type VoyageClient = V3BackendClient['voyage']

export function useGetAllStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)
  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.StatsService,
      method: 'all',
      params: {},
      client: v3BackendClient.stats,
      backendUrl,
    },
    { refreshInterval: AVERAGE_L1_BLOCK_TIME }
  )
}

export function useEarnAPRsV3Backend() {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.StatsService,
    method: 'earnAPYs',
    client: v3BackendClient.stats,
    params: {},
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return earnAPRsAdapter(data!)
    },
  }
}

export function useGetRatesEthUsdV3Backend(
  backendUrl: string,
  {
    refreshInterval,
    paused = false,
  }: {
    refreshInterval?: number
    paused?: boolean
  } = {}
) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'ethUsd',
      params: {},
      client: v3BackendClient.rates,
      backendUrl,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useGetRatesSwEthEthV3Backend({
  refreshInterval,
  paused = false,
}: {
  refreshInterval?: number
  paused?: boolean
} = {}) {
  // LST backend for sourcing swETH information
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'swethEth',
      params: {},
      client: v3BackendClient.rates,
      backendUrl: v3BackendLstUrl,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useGetRatesRswEthEthV3Backend({
  refreshInterval,
  paused = false,
}: {
  refreshInterval?: number
  paused?: boolean
} = {}) {
  // LRT backend for sourcing rswETH information
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLrtUrl)

  return useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.RatesService,
      method: 'swethEth', // TODO: backend may change to rswethEth
      params: {},
      client: v3BackendClient.rates,
      backendUrl: v3BackendLrtUrl,
    },
    {
      refreshInterval,
      paused,
    }
  )
}

export function useRepricingHistory() {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.RatesService,
    method: 'repricingHistory',
    params: {},
    client: v3BackendClient.rates,
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { prices } = data
      return {
        prices: prices.map(repriceAdapter),
      }
    },
  }
}

export function useTransactionsTokensV3Backend(
  backendUrl: string,
  address?: string
) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.OperatorService,
      method: 'transactionsTokens',
      client: v3BackendClient.operator,
      params: { address },
      backendUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { tokenTransactions } = data
      return {
        tokenTransactions: tokenTransactions.map(transactionTokenAdapter),
      }
    },
  }
}

export function useTransactionsNativeV3Backend(
  backendUrl: string,
  address?: string
) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.OperatorService,
      method: 'transactionsNative',
      client: v3BackendClient.operator,
      params: { address },
      backendUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { nativeTransactions } = data
      return {
        nativeTransactions: nativeTransactions.map(transactionNativeAdapter),
      }
    },
  }
}

export function useDepositManagerStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.OperatorService,
    method: 'depositsManagerStats',
    client: v3BackendClient.operator,
    params: {},
    backendUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return depositManagerStatsAdapter(data)
    },
  }
}

export function useDepositUserStatsV3Backend(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  return useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.OperatorService,
    method: 'depositsUserStats',
    client: v3BackendClient.operator,
    params: {},
    backendUrl,
  })
}

function useVoyageLeaderboardV3BackendProtobuf(backendUrl: string) {
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.VoyageService,
    method: 'voyageLeaderboard',
    client: v3BackendClient.voyage,
    params: {},
    backendUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { scores } = data
      return {
        scores: scores.map(voyageLeaderboardRecordAdapter),
      }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useVoyageLeaderboardV3BackendGet(): ReturnType<
  typeof useVoyageLeaderboardV3BackendProtobuf
> {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const safeV3Url = v3BackendLstUrl.replace(/\/$/, v3BackendLstUrl)

  return useSWR(
    'voyage-leaderboard',
    async () => {
      const data = await axios
        .get(
          `${safeV3Url}/swell.v3.VoyageService/v3BackendLstUrlconnect=v1&encoding=json&message={}`
        )
        .then((res) => {
          return res.data
        })

      const { scores } = data
      return { scores: scores.map(voyageLeaderboardRecordAdapter) } as any
    },
    {
      refreshInterval: 30_000,
    }
  )
}

// export { useVoyageLeaderboardV3BackendProtobuf as useVoyageLeaderboardV3Backend }
export { useVoyageLeaderboardV3BackendGet as useVoyageLeaderboardV3Backend }

export function useVoyageUserV3Backend(address?: string) {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.VoyageService,
      method: 'voyageUser',
      client: v3BackendClient.voyage,
      params: { address },
      backendUrl: v3BackendLstUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return voyageUserAdapter(data)
    },
  }
}

export function useEigenPointsUserV3Backend(address?: string) {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig() // TODO: not implemented on lst backend yet
  const backendUrl = v3BackendLstUrl
  const { v3BackendClient } = useV3BackendClient(backendUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.EigenPointsService,
      method: 'eigenPointsUser',
      client: v3BackendClient.eigenPoints,
      params: { address },
      backendUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return eigenPointsUserAdapter(data)
    },
  }
}

export function useVoyageStatusV3Backend() {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.VoyageService,
    method: 'voyageStatus',
    client: v3BackendClient.voyage,
    params: {},
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return voyageStatusAdapter(data)
    },
  }
}

export interface StakesV3BackendParams {
  first?: number
  skip?: number
  orderBy?: OrderBy
  orderDirection?: OrderDirection
  address?: string
  paused?: boolean
  stakeTransactionTypes: StakeTransactionType[]
}

export function useStakesV3Backend({
  first = 10,
  skip = 0,
  orderBy = OrderBy.TIMESTAMP,
  orderDirection = OrderDirection.DESC,
  address,
  paused = false,
  stakeTransactionTypes,
}: StakesV3BackendParams) {
  // LST backend is used for peripheral data
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.StakerService,
      method: 'stakes',
      client: v3BackendClient.staker,
      backendUrl: v3BackendLstUrl,
      params: {
        address,
        first,
        skip,
        orderBy,
        orderDirection,
        stakeTransactionTypes,
      },
    },
    {
      paused,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) {
        return undefined
      }
      const { stakes, count } = data

      return {
        stakes: stakes.map(stakesAdapter),
        count,
      }
    },
  }
}

export function useTwitterUserV3Backend(address?: string) {
  // LRT backend is used for peripheral mutations
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLrtUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.TwitterService,
      method: 'twitterUser',
      client: v3BackendClient.twitter,
      params: { walletAddress: address },
      backendUrl: v3BackendLrtUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        user: twitterUserAdapter(data),
      }
    },
  }
}

export function useTwitterVerifyV3Backend() {
  // LRT backend is used for peripheral mutations
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLrtUrl)

  const mutation = useProtobufMutation(v3BackendLrtUrl, {
    service: V3_BACKEND_SERVICES.TwitterService,
    method: 'twitterVerify',
    client: v3BackendClient.twitter,
  })

  return mutation
}

export function useGetSwethAprV3Backend() {
  // LST backend for sourcing swETH information
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const safeV3Url = v3BackendLstUrl.replace(/\/$/, '')

  return useSWR(
    'sweth-apr',
    async () => {
      return axios
        .get<number>(`${safeV3Url}/api/tokens/sweth/apr`)
        .then((res) => res.data)
    },
    {
      refreshInterval: 30000,
    }
  )
}

export function useGetRswethAprV3Backend() {
  // LRT backend for sourcing rswETH information
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const safeV3Url = v3BackendLrtUrl.replace(/\/$/, '')

  return useSWR(
    'rsweth-apr',
    async () => {
      return axios
        .get<number>(`${safeV3Url}/api/tokens/rsweth/apr`)
        .then((res) => res.data)
    },
    {
      refreshInterval: 30000,
    }
  )
}

export function useGetPreDepositStatsV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery(
    {
      service: V3_BACKEND_SERVICES.PreDepositService,
      method: 'stats',
      params: {},
      client: v3BackendClient.preDeposit,
      backendUrl: v3BackendLstUrl,
    },
    {
      refreshInterval: AVERAGE_L1_BLOCK_TIME,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const tokenStats: Record<
        string,
        {
          totalStaked: BigNumber
        }
      > = {}
      for (const token of data.tokenStats) {
        tokenStats[token.tokenAddress] = {
          totalStaked: BigNumber.from(token.totalStaked),
        }
      }

      return {
        tokenStats,
      }
    },
  }
}

export function usePreDepositTokenListV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.PreDepositService,
    method: 'tokenList',
    params: {},
    client: v3BackendClient.preDeposit,
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data?.tokenList) return undefined
      return {
        tokenList: tokenListProtobufAdapter(data.tokenList),
      }
    },
  }
}

export function usePreDepositTokenSupportedStatesV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable({
    service: V3_BACKEND_SERVICES.PreDepositService,
    method: 'tokenList',
    params: {},
    client: v3BackendClient.preDeposit,
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const tokenSupported: Record<
        string,
        { depositSupported: boolean; withdrawSupported: boolean }
      > = {}
      for (const token of data.tokenSupported) {
        tokenSupported[token.tokenAddress] = {
          depositSupported: token.depositSupported,
          withdrawSupported: token.withdrawSupported,
        }
      }

      return {
        tokenSupported,
      }
    },
  }
}

export function usePreDepositVoyageUserV3Backend(address?: string) {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQueryImmutable(
    {
      service: V3_BACKEND_SERVICES.PreDepositService,
      method: 'voyageUser',
      params: { address },
      client: v3BackendClient.preDeposit,
      backendUrl: v3BackendLstUrl,
    },
    {
      paused: !address,
    }
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        user: data,
      }
    },
  }
}

export function useGetPreDepositRatesUsdV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendLstUrl)

  const { data, ...query } = useProtobufQuery({
    service: V3_BACKEND_SERVICES.PreDepositService,
    method: 'ratesUsd',
    params: {},
    client: v3BackendClient.preDeposit,
    backendUrl: v3BackendLstUrl,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        rates: tokenRatesAdapter(data),
      }
    },
  }
}
