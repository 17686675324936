import { AirdropCarousel } from '@/components/AirdropCarousel'
import {
  useRecentDaoTokenClaim,
  useRestakedSwellDaoToken,
  useSwellDaoAirdrop,
  useSwellDaoAirdropContractsState,
  useSwellDaoAirdropOffchainState,
  useSwellDaoClaimAirdrop,
  useSwellDaoToken,
} from '@/state/dao/hooks'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { ROUTES } from '@/constants/routes'

const MIN_PEARLS_THRESHOLD = -1 // view disabled
const PLANKTON_THRESHOLD = 500
const CHECKER_MODE = true

export function AirdropPage() {
  const navigate = useNavigate()
  const { amountRestakedDaoToken, amountWithdrawnDaoToken, onClaimDaoToken } =
    useRecentDaoTokenClaim()

  useEffect(() => {
    if (!amountWithdrawnDaoToken || !amountRestakedDaoToken) {
      return
    }
    if (amountRestakedDaoToken.gt(0) || amountWithdrawnDaoToken.eq(0)) {
      return
    }
    navigate(ROUTES.StakeSwell)
  }, [amountRestakedDaoToken, amountWithdrawnDaoToken, navigate])

  const daoToken = useSwellDaoToken()
  const restakedDaoToken = useRestakedSwellDaoToken()
  const airdropResultQuery = useSwellDaoAirdrop()
  const airdropOffchainStateQuery = useSwellDaoAirdropOffchainState()
  const airdropContractsStateQuery = useSwellDaoAirdropContractsState()

  useEveryNthBlock({
    blocksPerFetch: 1,
    fetch: () => {
      airdropResultQuery.mutate()
    },
  })
  useEveryNthBlock({
    blocksPerFetch: 4,
    fetch: () => {
      airdropContractsStateQuery.mutate()
    },
  })
  useEveryNthBlock({
    blocksPerFetch: 24,
    fetch: () => {
      airdropOffchainStateQuery.mutate()
    },
  })

  const airdropResult = airdropResultQuery.data
  const airdropContractsState = airdropContractsStateQuery.data
  const airdropOffchainState = airdropOffchainStateQuery.data

  // calls
  const claimAirdrop = useSwellDaoClaimAirdrop()
  useWeb3CallErrorNotify(claimAirdrop)

  useEffect(() => {
    if (claimAirdrop.status !== claimAirdrop.STATUS.FULFILLED) {
      return
    }
    if (!claimAirdrop.args) {
      return
    }
    const { amountToLock, cumulativeAmount } = claimAirdrop.args[0]
    const withdrawn = cumulativeAmount.sub(amountToLock)
    onClaimDaoToken(withdrawn, amountToLock)
  }, [claimAirdrop, onClaimDaoToken])

  return (
    <AirdropCarousel
      airdropResult={airdropResult}
      minimumPearls={MIN_PEARLS_THRESHOLD}
      claimAirdrop={claimAirdrop}
      daoToken={daoToken}
      airdropContractsState={airdropContractsState}
      airdropOffchainState={airdropOffchainState}
      restakedDaoToken={restakedDaoToken}
      planktonPearlsThreshold={PLANKTON_THRESHOLD}
      checkerMode={CHECKER_MODE}
    />
  )
}
