import karakLogo from '@/assets/images/karak-logo-28x28.png'
import gauntletLogo from '@/assets/images/gauntlet-logo-28x28.png'
import aeraLogo from '@/assets/images/aera-logo-28x28.png'
import yearnLogo from '@/assets/images/yearn-logo-28x28.png'
import styled from 'styled-components'
import { hScrollStyle, labelStyle, valueStyle, vScrollStyle } from './common'
import { ExternalLinkIcon } from '@/swell-ui/icons/ExternalLinkIcon'

const ROW_HEIGHT = '22.6px'
const ICON_SIZE_PX = 24
const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'

const chainsecurityLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66b5b94ff9083824ece5af2d_Chainsecurity.svg'

const nethermindLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66b5b95004ba6bc25bf7e0e5_Nethermind.svg'

const chainSecurityAuditHref =
  'https://github.com/SwellNetwork/v3-core-public/blob/9765443eedc0f32f3b8ca6e11979a77e84509f25/Audit%20Reports/BTC%20LRT/ChainSecurity_Swell_swBTC_audit.pdf'
const nethermindAuditHref =
  'https://github.com/SwellNetwork/v3-core-public/blob/9765443eedc0f32f3b8ca6e11979a77e84509f25/Audit%20Reports/BTC%20LRT/NM_0273_SWELL_BTC_LRT.pdf'

const TERMS_OF_SERVICE_HREF =
  'https://www.swellnetwork.io/legal/terms-of-service'
const VAULT_AGREEMENT_HREF =
  'https://www.swellnetwork.io/legal/gauntlet-vault-agreement-and-disclaimer'

export function BtcLrtVaultDescription() {
  return (
    <Layout>
      <div>
        <div>
          <span className="label">Restaking Guardian</span>
          <span className="value row">
            <img
              className="round"
              src={gauntletLogo}
              width={ICON_SIZE_PX}
              height={ICON_SIZE_PX}
            />
            Gauntlet
          </span>
          <span className="label two-rows-mob">Powered by</span>
          <div className="row col-mob two-rows-mob">
            <span className="value row">
              <img
                className="round"
                src={aeraLogo}
                width={ICON_SIZE_PX}
                height={ICON_SIZE_PX}
              />{' '}
              Aera
            </span>
            <span className="value row">
              <img
                className="round"
                src={yearnLogo}
                width={ICON_SIZE_PX}
                height={ICON_SIZE_PX}
              />{' '}
              Yearn V3
            </span>
          </div>
          <span className="label">Restaking Protocols</span>
          <div className="row">
            <span className="value row">
              <img
                className="round"
                src={symbioticLogo}
                width={ICON_SIZE_PX}
                height={ICON_SIZE_PX}
              />{' '}
              Symbiotic
            </span>
          </div>
          <span className="label two-rows">Audited by</span>
          <div className="col two-rows two-rows-mob">
            <div className="value row">
              <img
                className="round"
                src={nethermindLogo}
                width={ICON_SIZE_PX}
                height={ICON_SIZE_PX}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={nethermindAuditHref}
              >
                Nethermind
                <ExternalLinkIcon className="ext" />
              </a>
            </div>
            <div className="value row">
              <img
                className="round"
                src={chainsecurityLogo}
                width={ICON_SIZE_PX}
                height={ICON_SIZE_PX}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={chainSecurityAuditHref}
              >
                ChainSecurity
                <ExternalLinkIcon className="ext" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <p>
            This is a multi-strategy vault that optimally restakes wBTC into a
            mix of BTC restaking protocols for maximum risk adjusted yields.
          </p>
          <br />
          <p>
            Disclaimer:
            <br />
            swBTC vault is not open to persons or citizens of the United States
            and other restricted countries - for more details please refer to
            the{' '}
            <a
              href={TERMS_OF_SERVICE_HREF}
              rel="noopener noreferrer"
              target="_blank"
            >
              terms of service
            </a>{' '}
            and{' '}
            <a
              href={VAULT_AGREEMENT_HREF}
              rel="noopener noreferrer"
              target="_blank"
            >
              vault agreement
            </a>
            .
          </p>
        </div>
      </div>
    </Layout>
  )
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  height: 100%;
  flex-grow: 1;

  > div:first-child {
    > div {
      overflow: auto;
      ${hScrollStyle};
      white-space: nowrap;

      display: grid;
      grid-template-columns: max-content 1fr;
      grid-auto-rows: ${ROW_HEIGHT};
      gap: 24px;
      align-items: center;

      .label {
        ${labelStyle}
      }
      .value {
        ${valueStyle}
        font-size: 14px; // crowded
        a {
          color: currentColor;
        }
        svg {
          margin-left: 8px;
          stroke: currentColor;
          &.ext {
            transform: translateY(2px);
          }
        }
      }

      .row {
        display: flex;
        flex-flow: row nowrap;
        gap: 6px;
      }

      img.round {
        border-radius: 50%;
      }

      .two-rows {
        align-self: start;
        grid-row: span 2;
      }
      .col {
        display: flex;
        flex-flow: column nowrap;
        gap: 12px;
      }
    }
  }

  > div:last-child {
    p {
      margin: 0;

      color: var(--white-125, #b0b0b0);
      /* Body/small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.42px;
    }

    flex: 1;
    align-self: stretch;
    justify-self: stretch;
    position: relative;
    > div {
      position: absolute;
      inset: 0;
      overflow: auto;
      padding-right: 4px;
      ${vScrollStyle};
    }
  }

  @media (max-width: 900px) {
    display: flex;
    flex-flow: column nowrap;

    > div:first-child {
      > div {
        .col-mob {
          display: flex;
          flex-flow: column nowrap;
          gap: 12px;
        }
        .two-rows-mob {
          align-self: start;
          grid-row: span 2;
        }
      }
    }
  }
`
