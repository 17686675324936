import { useRecentDaoTokenClaim } from '@/state/dao/hooks'
import { useEffect, useState } from 'react'

const ARRIVE_FROM_AIRDROP_OPEN_DELAY = 1200
export function useArriveFromAirdropModal() {
  const { amountRestakedDaoToken, amountWithdrawnDaoToken } =
    useRecentDaoTokenClaim()

  const [open, setOpen] = useState(false)
  function onClose() {
    setOpen(false)
  }
  useEffect(() => {
    if (!amountWithdrawnDaoToken || !amountRestakedDaoToken) {
      return
    }
    if (amountRestakedDaoToken.gt(0) || amountWithdrawnDaoToken.eq(0)) {
      return
    }

    const timeout = setTimeout(() => {
      setOpen(true)
    }, ARRIVE_FROM_AIRDROP_OPEN_DELAY)
    return () => clearTimeout(timeout)
  }, [amountRestakedDaoToken, amountWithdrawnDaoToken])

  return { open, onClose, amountWithdrawnDaoToken }
}
