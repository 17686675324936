import { BigNumber } from 'ethers'

// The purpose of this value is to be used as an upper bound for gas estimates for
//  creating stake transactions. It is also used to estimate the transaction fee of the
//  stake action in the UI.
//
// It is expected that a value this high should ensure the stake operation succeeds. At
//  run time, the maximum of (STAKE_GAS_ESTIMATE, $provider_gas_estimate) should be used
//  for safety (although unlikely our estimate will be exceeded).
//
// Sampled from one deposit transaction using the mock swETH contract

// Link to investigation which yielded the following value:
// https://www.notion.so/swellnetwork/v3-frontend-Gas-Estimations-d2987fd96e7c412ca4aee8b1f4176cb9?pvs=4#9cacea83fac846879f296523295275fc
const STAKE_GAS_UPPER_ESTIMATE = 120_000

export const getStakingUpperGasEstimate = () => {
  return BigNumber.from(STAKE_GAS_UPPER_ESTIMATE)
}
const SWETH_CREATE_WITHDRAW_REQUEST_GAS_ESTIMATE = 320_000
export const getSwETHCreateWithdrawRequestGasEstimate = () => {
  return BigNumber.from(SWETH_CREATE_WITHDRAW_REQUEST_GAS_ESTIMATE)
}
const SWETH_FINALIZE_WITHDRAWAL_GAS_ESTIMATE = 170_000
export const getSwETHFinalizeWithdrawalGasEstimate = () => {
  return BigNumber.from(SWETH_FINALIZE_WITHDRAWAL_GAS_ESTIMATE)
}

// ---

// The purpose of these values are to be used as upper bound for gas estimates for
//  creating addNewValidatorDetails transactions.
// To account for different batch sizes, an estimate for the smallest/largest possible batches
//  was procured.

// Link to investigation which yielded the following values:
// https://www.notion.so/swellnetwork/v3-frontend-Gas-Estimations-d2987fd96e7c412ca4aee8b1f4176cb9?pvs=4#57431725b5c44818b5ddbdd9c5781f44

// Chunk size: 50 keys
const VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_50 = 10_641_000
// Chunk size: 1 key
const VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_1 = 273_000

const LARGEST_ESTIMATED_CHUNK_SIZE = 50

export const getValidatorKeyGasEstimate = (nKeys: number) => {
  const rangeMagnitude =
    VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_50 -
    VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_1

  const base = VALIDATOR_KEY_SUBMISSION_GAS_UPPER_ESTIMATE_1

  return BigNumber.from(
    Math.ceil(base + rangeMagnitude * (nKeys / LARGEST_ESTIMATED_CHUNK_SIZE))
  )
}

// ---

const PRE_DEPOSIT_ZAP_GAS_UPPER_ESTIMATE = 250_000 // TODO: more data points
export const getPreDepositZapGasEstimate = () => {
  return BigNumber.from(PRE_DEPOSIT_ZAP_GAS_UPPER_ESTIMATE)
}

// deposit: https://etherscan.io/tx/0xcc4d392ef6a0cf6a0f4188bb440c121ad338e45be8c5afc090c98e32629b08bf = 121,194
const PRE_DEPOSIT_DEPOSIT_GAS_UPPER_ESTIMATE = 150_000 // TODO: more data points
export const getPreDepositDepositGasEstimate = () => {
  return BigNumber.from(PRE_DEPOSIT_DEPOSIT_GAS_UPPER_ESTIMATE)
}

// https://etherscan.io/tx/0x0cf387fac3997238025e8ec6e67d72839f82efb3cff073ff3b588ffd896178c1 = 52,984
const PRE_DEPOSIT_WITHDRAW_GAS_UPPER_ESTIMATE = 60_000 // TODO: more data points
export const getPreDepositWithdrawGasEstimate = () => {
  return BigNumber.from(PRE_DEPOSIT_WITHDRAW_GAS_UPPER_ESTIMATE)
}

const APPROVE_GAS_ESTIMATE = 60_000
export const getApproveGasEstimate = () => {
  return BigNumber.from(APPROVE_GAS_ESTIMATE)
}

const YEARN_DEPOSIT_GAS_ESTIMATE = 96_000
export function getYearnDepositGasEstimate() {
  return BigNumber.from(YEARN_DEPOSIT_GAS_ESTIMATE)
}

const YEARN_REQUEST_WITHDRAW_GAS_ESTIMATE = 85_000
export function getYearnRequestWithdrawGasEstimate() {
  return BigNumber.from(YEARN_REQUEST_WITHDRAW_GAS_ESTIMATE)
}

const YEARN_CANCEL_WITHDRAW_GAS_ESTIMATE = 85_000
export function getYearnCancelWithdrawGasEstimate() {
  return BigNumber.from(YEARN_CANCEL_WITHDRAW_GAS_ESTIMATE)
}

const YEARN_COMPLETE_WITHDRAW_GAS_ESTIMATE = 121_000
export function getYearnCompleteWithdrawGasEstimate() {
  return BigNumber.from(YEARN_COMPLETE_WITHDRAW_GAS_ESTIMATE)
}

const RSWETH_CREATE_WITHDRAW_REQUEST_GAS_ESTIMATE = 320_000
export const getRswETHCreateWithdrawRequestGasEstimate = () => {
  return BigNumber.from(RSWETH_CREATE_WITHDRAW_REQUEST_GAS_ESTIMATE)
}

const RSWETH_FINALIZE_WITHDRAWAL_GAS_ESTIMATE = 170_000
export const getRswETHFinalizeWithdrawGasEstimate = () => {
  return BigNumber.from(RSWETH_FINALIZE_WITHDRAWAL_GAS_ESTIMATE)
}

// Merkle drops
export type LockGasType = 'yearn' | 'staking' | 'none'

/*   
Gas used - First time claim with no lock:                     54,600
Gas used - Second time claim with no lock:                    11,021

Gas used - First time claim with fractional lock (IStaking):  131,819
Gas used - Second time claim with fractional lock (IStaking): 88,184
Gas used - First time claim with full lock (IStaking):        106,085
Gas used - Second time claim with full lock (IStaking):       84,408

Gas used - First time claim with fractional lock (Yearn):     150,593
Gas used - Second time claim with fractional lock (Yearn):    71,705
Gas used - First time claim with full lock (Yearn):           125,121
Gas used - Second time claim with full lock (Yearn):          46,235
*/

export function merkleDropClaimAndLockGasEstimate({
  cumulativeClaimed,
  amountToLock,
  cumulativeAmount,
  lockType,
}: {
  cumulativeClaimed: BigNumber
  cumulativeAmount: BigNumber
  amountToLock: BigNumber
  lockType: LockGasType
}) {
  const firstTime = cumulativeClaimed.isZero()
  const fullLock = amountToLock.eq(cumulativeAmount)
  const fractionalLock = amountToLock.gt(0) && amountToLock.lt(cumulativeAmount)
  const noLock = amountToLock.isZero()

  // no lock
  if (firstTime && noLock) {
    return BigNumber.from(55_000)
  }
  if (!firstTime && noLock) {
    return BigNumber.from(11_500)
  }

  if (lockType === 'staking') {
    if (firstTime && fractionalLock) {
      return BigNumber.from(132_000)
    }
    if (!firstTime && fractionalLock) {
      return BigNumber.from(89_000)
    }
    if (firstTime && fullLock) {
      return BigNumber.from(107_000)
    }
    if (!firstTime && fullLock) {
      return BigNumber.from(85_000)
    }
  }

  if (lockType === 'yearn') {
    if (firstTime && fractionalLock) {
      return BigNumber.from(151_000)
    }
    if (!firstTime && fractionalLock) {
      return BigNumber.from(72_000)
    }
    if (firstTime && fullLock) {
      return BigNumber.from(126_000)
    }
    if (!firstTime && fullLock) {
      return BigNumber.from(47_000)
    }
  }

  // (misconfigured) lock - none

  if (firstTime) {
    return BigNumber.from(55_000)
  }
  return BigNumber.from(11_500)
}
