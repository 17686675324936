import { StyledButtonThin } from '@/components/AirdropCarousel/AirdropButtons'
import { paragraphHintCSS } from '@/components/AirdropCarousel/styles/common'
import { DecoBox } from '@/components/DecoBox'
import { TokenLogo } from '@/components/TokenLogo'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { Dialog } from '@mui/material'
import { BigNumber } from 'ethers'
import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'

const headlinesH7CSS = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`

export function ArriveFromAirdropModal({
  onConfirm,
  daoToken,
  claimedAmount,
}: {
  onConfirm: () => void
  claimedAmount: BigNumber
  daoToken: Token
}) {
  const claimedAMountStr = displayCryptoLocale(
    claimedAmount,
    daoToken.decimals,
    { precision: 2, roundLarge: true }
  )

  return (
    <MuiDialog open onClose={() => {}}>
      <Layout
        direction="column"
        gap="16"
        padding="42px !important"
        align="start"
        opaque
      >
        <h2>Congratulations!</h2>
        <Content>
          <FlexRow gap="12" align="center" justify="center">
            <TokenLogo token={TOKEN_LIST_SWELL} size={27} />
            <span className="headline-h7">
              {claimedAMountStr} {daoToken.symbol}
            </span>
          </FlexRow>
          <p className="hint">
            Restake your {daoToken.symbol} to get even more benefits.
          </p>
        </Content>
        <Btns gap="16">
          <StyledButtonThin onClick={onConfirm}>OK</StyledButtonThin>
        </Btns>
      </Layout>
    </MuiDialog>
  )
}

const MuiDialog = styled(Dialog)`
  .MuiPaper-root {
    /* border-radius: 12px; */
    border: none;
    background: none;
    padding: none;
    overflow: visible;
  }
`

const Content = styled.div`
  height: auto;
  width: 100%;
  padding: 12px 0;

  .headline-h7 {
    ${headlinesH7CSS}
  }
  .hint {
    ${paragraphHintCSS}
  }
`

const Btns = styled(FlexRow)`
  button {
    flex: 1 1 auto;
  }
`

const Layout = styled(DecoBox)`
  h2 {
    margin: 0 auto;
    text-align: center;
    max-width: 80%;
    color: var(--Swell-White-50, #fff);
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }

  button {
    width: 100%;
  }

  ul {
    padding: 0;
    margin: 0;
    margin-left: 29px;
  }
`
