import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { PreparedSwellDaoClaimAirdrop } from './airdropCalls'
import { useWeb3Button } from '@/hooks/useWeb3Button'
import styled, { css } from 'styled-components'
import { Button } from '@/swell-ui/Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import { buttonThinCSS } from '@/components/AirdropCarousel/styles/common'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Chip } from '@/swell-ui/Chip'
import { FlexRow } from '@/swell-ui/FlexRow'
import { MerkleDropState } from '@/types/merkle'
import { BigNumber } from 'ethers'
import { useState } from 'react'
import { ConsiderRestakingModal } from './views/ClaimAirdrop/ConsiderRestakingModal'
import { AirdropCompletionSummary } from './types'
import { useMediaQuery } from '@mui/material'

export const StyledButtonThin = styled(Button)`
  margin: 0 auto;
  ${buttonThinCSS}

  &:disabled {
    svg {
      opacity: 0.5;
    }
  }
`
const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`

export function ConnectWalletButton() {
  const { connect } = useSwellWeb3()
  return (
    <StyledButtonThin onClick={() => connect()}>
      Connect Wallet
    </StyledButtonThin>
  )
}

export function ButtonLink({
  href,
  outline,
  children,
}: {
  href: string
  children: React.ReactNode
  outline?: boolean
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <StyledButtonThin variant={outline ? 'secondary' : 'primary'}>
        {children}
      </StyledButtonThin>
    </a>
  )
}

export function StartAirdropButton({ start }: { start: () => void }) {
  return (
    <StyledButtonThin onClick={start}>
      <StartAirdropLayout align="center" justify="center">
        Next <ArrowR />
      </StartAirdropLayout>
    </StyledButtonThin>
  )
}

const StartAirdropLayout = styled(FlexRow)`
  .arrow-r {
    margin-left: 6px;
    margin-top: 2px;
  }
`

export function ClaimAirdropButton({
  prepared,
  claimAirdrop,
  merkleStatus,
}: {
  prepared: PreparedSwellDaoClaimAirdrop
  claimAirdrop: SwellDaoClaimAirdrop
  merkleStatus: MerkleDropState
}) {
  const [showingConsiderRestakeModal, setShowingConsiderRestakeModal] =
    useState(false)
  function showConsiderRestakeModal() {
    setShowingConsiderRestakeModal(true)
  }
  function hideConsiderRestakeModal() {
    setShowingConsiderRestakeModal(false)
  }

  let idleLabel = 'Claim'
  if (merkleStatus && !merkleStatus.claimIsOpen) {
    idleLabel = 'Claim not open'
  }

  const btn = useWeb3Button(claimAirdrop, claimAirdrop.call, prepared.args, {
    idleLabel,
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    fulfilledLabel: 'Claimed',
  })

  function onClick() {
    if (!prepared.args) return
    const { amountToLock } = prepared.args[0]

    if (amountToLock.gt(BigNumber.from(0))) {
      return btn.onClick()
    }

    showConsiderRestakeModal()
  }

  return (
    <>
      <StyledButtonThin disabled={btn.disabled} onClick={onClick}>
        {btn.label}
      </StyledButtonThin>

      {showingConsiderRestakeModal && (
        <ConsiderRestakingModal
          onConfirm={() => {
            btn.onClick()
            hideConsiderRestakeModal()
          }}
          onCancel={hideConsiderRestakeModal}
        />
      )}
    </>
  )
}

const StyledChip = styled(Chip)<{ selected: boolean }>`
  width: 69px;
  height: 32px;
  border-radius: 100px;
  border: 1px solid var(--white-50, #fff);
  transition: all 0.25s linear;

  color: var(--white-50, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  ${({ selected }) =>
    selected &&
    css`
      &,
      &:hover {
        background: #2f43ec !important;
        border: none;
      }
    `};

  @media (max-width: 460px) {
    width: 58px;
    font-size: 10px;
    line-height: 20px;
  }
`

export function SliderChipGroup({
  lockRatio,
  setLockRatio,
  disabled,
}: {
  lockRatio: number
  setLockRatio: (value: number) => void
  disabled: boolean
}) {
  const is460Up = useMediaQuery('(min-width: 460px)')
  const gap = is460Up ? '12' : '8'

  return (
    <FlexRow gap={gap} justify="space-between">
      <StyledChip
        label={'25%'}
        variant="outlined"
        disabled={disabled}
        onClick={() => setLockRatio(0.25)}
        selected={Math.abs(lockRatio - 0.25) < 0.01}
      />
      <StyledChip
        label={'50%'}
        variant="outlined"
        disabled={disabled}
        onClick={() => setLockRatio(0.5)}
        selected={Math.abs(lockRatio - 0.5) < 0.01}
      />
      <StyledChip
        label={'75%'}
        variant="outlined"
        disabled={disabled}
        onClick={() => setLockRatio(0.75)}
        selected={Math.abs(lockRatio - 0.75) < 0.01}
      />
      <StyledChip
        label={'Max'}
        variant="outlined"
        disabled={disabled}
        onClick={() => setLockRatio(1)}
        selected={Math.round(lockRatio * 100) === 100}
      />
    </FlexRow>
  )
}

export function createShareAirdropIntentHref({
  claimedToWalletAmount,
}: AirdropCompletionSummary): string {
  return claimedToWalletAmount.toString() // todo
}

export function AirdropTwitterShareButton({
  summary,
}: {
  summary: AirdropCompletionSummary
}) {
  const href = createShareAirdropIntentHref(summary)
  return (
    <ButtonLink href={href} outline={false}>
      <XIcon /> Share
    </ButtonLink>
  )
}

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <g clip-path="url(#clip0_10793_2265)">
        <path
          d="M4.125 2.8125H7.5L15.375 15.1875H12L4.125 2.8125Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.75719 10.0918L4.125 15.1873"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3744 2.8125L10.7422 7.90805"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10793_2265">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

function ArrowR() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      className="arrow-r"
    >
      <g filter="url(#filter0_dd_10845_2300)">
        <path
          d="M4.8125 8.335H4.1475V9.665H4.8125V8.335ZM17.1875 9.665C17.5548 9.665 17.8525 9.36727 17.8525 9C17.8525 8.63273 17.5548 8.335 17.1875 8.335V9.665ZM4.8125 9.665H17.1875V8.335H4.8125V9.665Z"
          fill="white"
        />
        <path
          d="M12.125 3.9375L17.1875 9L12.125 14.0625"
          stroke="white"
          stroke-width="1.33"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_10845_2300"
          x="-2"
          y="0"
          width="26"
          height="26"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10845_2300"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_10845_2300"
            result="effect2_dropShadow_10845_2300"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_10845_2300"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
