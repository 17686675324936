import { useSwellDaoToken } from '@/state/dao/hooks'
import { ArriveFromAirdropModal } from './ArriveFromAirdropModal'
import { useArriveFromAirdropModal } from './hooks'

export function RSWELLPage() {
  const daoToken = useSwellDaoToken()

  const arriveFromAirdropModal = useArriveFromAirdropModal()

  return (
    <>
      {arriveFromAirdropModal.open && (
        <ArriveFromAirdropModal
          onConfirm={() => {
            arriveFromAirdropModal.onClose()
          }}
          claimedAmount={arriveFromAirdropModal.amountWithdrawnDaoToken!}
          daoToken={daoToken}
        />
      )}
    </>
  )
}
