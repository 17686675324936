import {
  useV3BackendClient,
  VoyageClient,
  WalletClient,
} from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { SwellDaoContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  AirdropOffchainState,
  AirdropResult,
  IDaoApiRead,
  IDaoApiWrite,
} from './types'
import { BigNumber, ethers } from 'ethers'
import { Multicall3 } from '@/abis/types'
import { DaoMerkleDrop, DaoMerkleDropKind } from '@/types/airdrop'
import { CumulativeMerkleDrop__factory } from '@/abis/types/factories/CumulativeMerkleDrop__factory'
import {
  useIERC20Contract,
  useMerkleDropContract,
  useMulticallContract,
} from '@/hooks/useContract'
import { Token } from '@/types/tokens'
import { prefix0x } from '@/util/hexStrings'
import { MerkleContractsState } from '@/types/merkle'
import { useState } from 'react'

export function useDaoApiImpl({
  merkleDropAirdrop,
  daoToken,
  restakedDaoToken,
}: {
  daoToken: Token
  restakedDaoToken: Token
  merkleDropAirdrop: DaoMerkleDrop
}): SwellDaoContext {
  const [amountWithdrawnDaoToken, setAmountWithdrawnDaoToken] =
    useState<BigNumber>()
  const [amountRestakedDaoToken, setAmountRestakedDaoToken] =
    useState<BigNumber>()

  const onClaimDaoToken = (
    amountWithdrawn: BigNumber,
    amountRestaked: BigNumber
  ) => {
    setAmountWithdrawnDaoToken(amountWithdrawn)
    setAmountRestakedDaoToken(amountRestaked)
  }
  const clearRecentDaoTokenClaim = () => {
    setAmountWithdrawnDaoToken(undefined)
    setAmountRestakedDaoToken(undefined)
  }

  return {
    clearRecentDaoTokenClaim,
    onClaimDaoToken,
    amountRestakedDaoToken,
    amountWithdrawnDaoToken,
    daoToken,
    restakedDaoToken,
    merkleDropAirdrop,
    read: useDaoApiRead({
      daoToken,
      merkleDropAirdrop,
    }),
    write: useDaoApiWrite({
      merkleDropAirdrop,
    }),
  }
}

async function fetchAirdropUser({
  multicall,
  account,
  merkleDropAirdrop,
  walletClient,
  voyageClient,
}: {
  multicall: Multicall3
  merkleDropAirdrop: DaoMerkleDrop
  account: string
  walletClient: WalletClient
  voyageClient: VoyageClient
}): Promise<AirdropResult> {
  if (merkleDropAirdrop.kind !== DaoMerkleDropKind.Airdrop) {
    throw new Error('merkle drop kind')
  }

  // const airdropDataP = walletClient.airdrop({ walletAddress: account })
  const checkerResp = walletClient.airdropChecker({ walletAddress: account })
  // N/A for now
  const calls: Multicall3.Call3Struct[] = []
  calls.push({
    target: merkleDropAirdrop.address,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropAirdrop.address,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'cumulativeClaimed',
        [account]
      ),
  })

  // const [merkleRootResult, cumulativeClaimedResult] =
  //   await multicall.callStatic.tryAggregate(true, calls)

  // const currentMerkleRoot =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'merkleRoot',
  //     merkleRootResult.returnData
  //   )[0]
  // const cumulativeClaimed =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'cumulativeClaimed',
  //     cumulativeClaimedResult.returnData
  //   )[0]

  const airdropData = await checkerResp
  // if (prefix0x(airdropData.latestMerkleRootHex) !== currentMerkleRoot) {
  //   throw new Error('Merkle root mismatch')
  // }

  const pearls = airdropData.pearlAmount ?? 0
  if (airdropData.totalAmount === '' || airdropData.totalAmount === '0') {
    return {
      exists: false,
      pearls: pearls,
      isSybil: airdropData.isSybil,
    }
  }

  const loyaltyAmount = BigNumber.from(airdropData.loyaltyAmount)

  // shortcut, the UI treats cumulativeAmount == total amount as non-whale
  let cumulativeAmount: BigNumber
  if (airdropData.isWhale) {
    cumulativeAmount = BigNumber.from(0)
  } else {
    cumulativeAmount = BigNumber.from(airdropData.totalAmount)
  }

  return {
    exists: true,
    data: {
      address: account,
      merkleProof: [], // N/A for now
      merkleRoot: '0x', // N/A for now
      cumulativeAmount,
      totalAmount: BigNumber.from(airdropData.totalAmount),
    },
    pearls,
    cumulativeClaimed: BigNumber.from(0),
    loyaltyAmount,
    isSybil: airdropData.isSybil,
    claimedEvents: [], // N/A for now
  }
}

async function fetchAirdropContractsState({
  merkleDropAirdropAddress,
  multicall,
}: {
  merkleDropAirdropAddress: string
  multicall: Multicall3
}): Promise<MerkleContractsState> {
  // const calls: Multicall3.Call3Struct[] = []
  // calls.push({
  //   target: merkleDropAirdropAddress,
  //   allowFailure: false,
  //   callData:
  //     CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
  //       'merkleRoot'
  //     ),
  // })
  // calls.push({
  //   target: merkleDropAirdropAddress,
  //   allowFailure: false,
  //   callData:
  //     CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
  //       'claimIsOpen'
  //     ),
  // })
  // calls.push({
  //   target: merkleDropAirdropAddress,
  //   allowFailure: false,
  //   callData:
  //     CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
  //       'stakingContract'
  //     ),
  // })

  // const [merkleRootAirdropResult, claimIsOpenAirdropResult, stakingContract] =
  //   await multicall.callStatic.tryAggregate(true, calls)

  // const merkleRootAirdrop =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'merkleRoot',
  //     merkleRootAirdropResult.returnData
  //   )[0]
  // const claimIsOpenAirdrop =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'claimIsOpen',
  //     claimIsOpenAirdropResult.returnData
  //   )[0]
  // const stakingContractAddress =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'stakingContract',
  //     stakingContract.returnData
  //   )[0]

  // const stakingExists = stakingContractAddress !== ethers.constants.AddressZero

  return {
    merkleDrop: {
      claimIsOpen: false,
      merkleRoot: '0x',
    },
    // staking: stakingExists
    //   ? { exists: true, kind: 'vault', isPaused: false }
    //   : { exists: false },
    staking: { exists: false },
  }
}
async function fetchAirdropOffchainState(): Promise<AirdropOffchainState> {
  return {
    // nextAirdropVestingUnlockUnix: STUB_NEXT_VESTING_UNLOCK_UNIX,
  }
}

function useDaoApiRead({
  daoToken,
  merkleDropAirdrop,
}: {
  daoToken: Token
  merkleDropAirdrop: DaoMerkleDrop
}): IDaoApiRead {
  const { account: maybeAccount } = useSwellWeb3()
  const multicall = useMulticallContract()
  const { v3BackendLrtUrl, v3BackendLstUrl } = useDeploymentSetConfig()
  const walletClientLST =
    useV3BackendClient(v3BackendLstUrl).v3BackendClient.wallet
  const walletClientLRT =
    useV3BackendClient(v3BackendLrtUrl).v3BackendClient.wallet

  const statsClient = useV3BackendClient(v3BackendLstUrl).v3BackendClient.stats
  const voyageClient =
    useV3BackendClient(v3BackendLstUrl).v3BackendClient.voyage
  const merkleDropContractAirdrop = useMerkleDropContract(
    merkleDropAirdrop.address
  )!

  const swellTokenContract = useIERC20Contract(daoToken.address)!

  const account = maybeAccount!
  return {
    balances: async () => {
      return {
        swellBalance: await swellTokenContract.balanceOf(account),
      }
    },
    airdrop: async () => {
      if (!account) {
        throw new Error('No account')
      }

      return fetchAirdropUser({
        account,
        voyageClient,
        merkleDropAirdrop,
        multicall,
        walletClient: walletClientLRT,
      })
    },
    airdropContractsState: async () => {
      return fetchAirdropContractsState({
        merkleDropAirdropAddress: merkleDropAirdrop.address,
        multicall,
      })
    },
    airdropOffchainState: async () => {
      return fetchAirdropOffchainState()
    },
    wavedrop: async () => {
      const { blackPearls, multiplier } = await walletClientLST.wavedropUser({
        walletAddress: account,
      })
      return { blackPearls, multiplier }
    },
    wavedrop2: async () => {
      const { blackPearls, multiplier } = await walletClientLST.wavedrop2User({
        walletAddress: account,
      })
      return { blackPearls, multiplier }
    },
    waveDropStats: async () => {
      const { currentWaveNumber, nextWavedropEndUnix } =
        await statsClient.wavedropStats({})
      return { nextWavedropEndUnix, currentWaveNumber }
    },
    checkClaimAirdrop: async ({ merkleProof, cumulativeAmount }) => {
      return merkleDropContractAirdrop.verifyProof(
        merkleProof,
        cumulativeAmount,
        account
      )
    },
  }
}

function useDaoApiWrite({
  merkleDropAirdrop,
}: {
  merkleDropAirdrop: DaoMerkleDrop
}): IDaoApiWrite {
  const merkleDropAirdropContract = useMerkleDropContract(
    merkleDropAirdrop.address
  )!
  return {
    claimAirdrop: async (
      { amountToLock, cumulativeAmount, merkleProof },
      opts
    ) => {
      return merkleDropAirdropContract.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof,
        opts
      )
    },
    claimAirdropEstimateGas: async ({
      amountToLock,
      cumulativeAmount,
      merkleProof,
    }) => {
      return merkleDropAirdropContract.estimateGas.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof
      )
    },
  }
}
