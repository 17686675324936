import { DecoBox } from '@/components/DecoBox'
import { medValueCSS } from '@/components/AirdropCarousel/styles/common'
import { Token } from '@/types/tokens'
import styled from 'styled-components'
import { AirdropResult } from '@/state/dao/types'
import { displayVestingBreakdown } from '../../airdropFormatting'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useMediaQuery } from '@mui/material'

const LINK_TO_VESTING_FORM = 'https://9zz8dr00fbt.typeform.com/swell'

export function VestingSummary({
  daoToken,
  airdropResult,
}: {
  airdropResult: AirdropResult
  daoToken: Token
}) {
  const isDesktop = useMediaQuery('(min-width: 990px)')
  if (!airdropResult.exists) return null
  const pt1CumulativeAmount = airdropResult.data.cumulativeAmount

  const { data } = airdropResult
  const {
    firstUnlockClaimableAmount,
    firstUnlockClaimablePercent,
    initialClaimableAmount,
    initialClaimablePercent,
    secondUnlockClaimableAmount,
    secondUnlockClaimablePercent,
    thirdUnlockClaimableAmount,
    thirdUnlockClaimablePercent,
    vestedPercent,
  } = displayVestingBreakdown({
    daoToken,
    data,
    initialCumulativeAmount: pt1CumulativeAmount,
  })

  const innerContent = (
    <>
      <FlexRow direction="column">
        <h4 className="sm-header">TGE ({initialClaimablePercent})</h4>
        <span className="value">{initialClaimableAmount} SWELL</span>
      </FlexRow>
      <FlexRow direction="column">
        <h4 className="sm-header">
          TGE + 30 Days ({firstUnlockClaimablePercent})
        </h4>
        <span className="value">{firstUnlockClaimableAmount} SWELL</span>
      </FlexRow>
      <FlexRow direction="column">
        <h4 className="sm-header">
          TGE + 60 Days ({secondUnlockClaimablePercent})
        </h4>
        <span className="value">{secondUnlockClaimableAmount} SWELL</span>
      </FlexRow>
      <FlexRow direction="column">
        <h4 className="sm-header">
          TGE + 90 Days ({thirdUnlockClaimablePercent})
        </h4>
        <span className="value">{thirdUnlockClaimableAmount} SWELL</span>
      </FlexRow>
    </>
  )

  const desktopContent = (
    <DesktopLayout gap="24" align="stretch">
      {innerContent}
    </DesktopLayout>
  )

  const mobileContent = (
    <MobileLayout direction="column" gap="24">
      {innerContent}
    </MobileLayout>
  )

  let content = mobileContent
  if (isDesktop) {
    content = desktopContent
  }

  return (
    <StyledDecoBox gap="24" align="start">
      <h4>Vested allocation</h4>
      {/* <p className="hint">
        Due to the size of your SWELL allocation a portion of your token will be
        subject to vesting to ensure fairness and long term growth of the Swell
        ecosystem. An initial {initialClaimablePercent} will be vested and
        claimable on TGE, the remaining {vestedPercent} will be split into 3
        equal portions to be vested every 30 days from TGE.
      </p> */}

      {/* temporarily omitting details */}

      <p className="hint">
        Due to the size of your SWELL allocation a portion of your token will be
        subject to vesting to ensure fairness and long term growth of the Swell
        ecosystem. Please fill out{' '}
        <a href={LINK_TO_VESTING_FORM} target="_blank" rel="noreferrer">
          this form
        </a>{' '}
        for our team to reach out to you to discuss.
      </p>

      {/* {content} */}
    </StyledDecoBox>
  )
}

const InnerBox = styled(FlexRow)`
  border: 1px solid var(--lightblue-100, #2f43ec);
  border-radius: 12px;
  justify-content: space-around;
  padding: 24px 62px;
  width: 100%;
  text-align: center;

  &:first-child {
    width: 432px;
    white-space: nowrap;
  }

  color: #fff;
`

const DesktopLayout = styled(InnerBox)`
  white-space: nowrap;
`
const MobileLayout = styled(InnerBox)`
  padding: 16px;
`

const StyledDecoBox = styled(DecoBox)`
  h4 {
    margin: 0;
    color: var(--Swell-Lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: 2.52px;
    text-transform: uppercase;
  }

  h4.sm-header {
    color: var(--Swell-Lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 16px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .value {
    ${medValueCSS}
  }
`
